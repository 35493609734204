import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom'
import './App.css'

import Home from './pages/Home'
import BandArt from './pages/BandArt'
import OtherDoodles from './pages/OtherDoodles'
import StoryDoodles from './pages/StoryDoodles'
import Summon from './pages/Summon'
import DiscogsFrog from './pages/DiscogsFrog'

const App = () => {
  return (
    <Router>
      <nav>
        <ul>
          <li>
            <Link to='/'>Home</Link>
          </li>
          <li>
            <Link to='/discogs-frog'>Discogs Frog</Link>
          </li>
          {/*<li>
            <Link to='/band-art'>Band Art</Link>
          </li>
          <li>
            <Link to='/story-doodles'>Story Doodles</Link>
          </li>
          <li>
            <Link to='/other-doodles'>Other Doodles</Link>
          </li>
          <li>
            <Link to='/summon-me'>Summon Me</Link>
          </li>*/}
        </ul>
      </nav>

      <Switch>
        <Route path='/band-art'>
          <BandArt />
        </Route>
        <Route path='/story-doodles'>
          <StoryDoodles />
        </Route>
        <Route path='/other-doodles'>
          <OtherDoodles />
        </Route>
        <Route path='/summon-me'>
          <Summon />
        </Route>
        <Route path='/discogs-frog'>
          <DiscogsFrog />
        </Route>
        <Route path='/'>
          <Home />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
