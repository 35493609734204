import React, { useState, useEffect } from 'react'

import { getCollectionForUser } from '../api'

const DiscogsFrog = () => {
  const [collection, setCollection] = useState([])
  const [vinylChoice, setVinylChoice] = useState(null)
  const [users, setUsers] = useState('')
  const [loading, setLoading] = useState(false)

  const findVinyl = event => {
    event.preventDefault()
    setCollection([])

    if (users.trim() === '') {
      setVinylChoice('Discogs frog needs at least one username!')
      return
    }

    setVinylChoice('Loading...Ribbet...RIBBET...')
    setLoading(true)
    callGetCollectionForUsers(users.split(','))
  }

  const callGetCollectionForUsers = async users => {
    let promises = []
    let requestsPerUser = []

    users.forEach(user => {
      promises.push(
        getCollectionForUser(user.trim(), 1).then(data => {
          if (data.releases && Array.isArray(data.releases) && data.releases.length > 0) {
            requestsPerUser.push({
              username: user.trim(),
              chunks: data.pagination.pages
            })
          }
        })
      )
    })

    await Promise.all(promises).then(() => {
      let promises = []
      let formattedReleases = []
      requestsPerUser.forEach(user => {
        for (let i = 1; i < user.chunks + 1; i++) {
          promises.push(
            getCollectionForUser(user.username, i).then(data => {
              if (data.releases && Array.isArray(data.releases) && data.releases.length > 0) {
                formattedReleases.push(formatReleases(data.releases))
              }
            })
          )
        }
      })

      Promise.all(promises).then(() => {
        setCollection(formattedReleases.flat())
        setLoading(false)
      }).catch((data) => {
        if (data.message.includes(401)) {
          setVinylChoice(`CROAK...one of your users has a private collection...don't break my frog, my achey breaky frog...`)
        } else {
          setVinylChoice(`CROAK...one of your usernames is invalid...don't break my frog, my achey breaky frog...`)
        }
        setLoading(false)
      })
    }).catch((data) => {
      if (data.message.includes(401)) {
        setVinylChoice(`CROAK...one of your users has a private collection...don't break my frog, my achey breaky frog...`)
      } else {
        setVinylChoice(`CROAK...one of your usernames is invalid...don't break my frog, my achey breaky frog...`)
      }
      setLoading(false)
    })
  }

  const formatReleases = releases => {
    return releases.map(release => {
      return `${release.basic_information.title} by ${release.basic_information.artists[0].name}`
    })
  }

  const addUsers = event => {
    setUsers(event.target.value)
  }

  useEffect(() => {
    if (collection.length > 0) {
      setVinylChoice(collection[Math.floor(Math.random() * collection.length)])
    }
  }, [collection])

  return (
    <main>
      <h1>Discogs Frog</h1>
      <div className='frogContainer'>
        <svg className='frog' width='350' height='320' xmlns='http://www.w3.org/2000/svg' role='img'>
          <title>Frog Face</title>
          <ellipse strokeWidth='5' ry='117.5' rx='156' id='svg_1' cy='179' cx='192.5' stroke='#000' fill='#7fff00' />
          <ellipse strokeWidth='5' ry='49' rx='50.5' id='svg_2' cy='69.5' cx='124' stroke='#000' fill='#7fff00' />
          <ellipse strokeWidth='5' ry='49.5' rx='50.5' id='svg_3' cy='80' cx='287' stroke='#000' fill='#7fff00' />
          <ellipse ry='29' rx='25.5' id='svg_5' cy='77.5' cx='129' stroke='#000' fill='#f7f9f4' />
          <ellipse ry='27' rx='27' id='svg_8' cy='87.5' cx='281.5' stroke='#000' fill='#f9f9f9' />
          <ellipse strokeWidth='12' stroke='#000' ry='36' rx='36' id='svg_9' cy='71.5' cx='125.5' fill='#000000' />
          <ellipse ry='19.5' rx='14.5' id='svg_10' cy='94' cx='281' stroke='#000' fill='#000000' />
          <ellipse rx='0.5' id='svg_11' cy='-46.5' cx='-37' stroke='#000' fill='#000000' />
          <ellipse strokeWidth='10' stroke='#000' ry='36' rx='36' id='svg_15' cy='81.5' cx='286.5' fill='#000000' />
          <ellipse ry='14' rx='13.5' id='svg_16' cy='70.5' cx='126' stroke='#000' fill='#ffffff' />
          <ellipse ry='14' rx='14' id='svg_18' cy='80.5' cx='287.5' stroke='#000' fill='#ffffff' />
          <ellipse ry='3' rx='2' id='svg_19' cy='69.5' cx='125.5' stroke='#000' fill='#000000' />
          <ellipse ry='2' rx='2' id='svg_20' cy='78.5' cx='286.5' stroke='#000' fill='#000000' />
          <ellipse ry='28.5' rx='29' id='svg_24' cy='70' cx='125.5' stroke='#ffffff' fill='none' />
          <ellipse ry='28.5' rx='29' id='svg_25' cy='80' cx='287.5' stroke='#ffffff' fill='none' />
          <ellipse stroke='#ffffff' ry='23.5' rx='23.5' id='svg_26' cy='70' cx='126' fill='none' />
          <ellipse stroke='#ffffff' ry='23.5' rx='23.5' id='svg_27' cy='80' cx='288' fill='none' />
          <ellipse stroke='#ffffff' ry='33.5' rx='35' id='svg_28' cy='70' cx='126.5' fill='none' />
          <ellipse stroke='#ffffff' ry='32.5' rx='33' id='svg_29' cy='80' cx='287.5' fill='none' />
          <ellipse stroke='#000' ry='61' rx='99.99999' id='svg_35' cy='204.5' cx='191.5' strokeWidth='5' fill='none' />
          <rect stroke='#7fff00' id='svg_38' height='82' width='231' y='139.5' x='77.5' strokeWidth='5' fill='#7fff00' />
          <ellipse ry='6' rx='3.5' id='svg_39' cy='177.5' cx='170' strokeWidth='10' stroke='#000' fill='#000000' />
          <ellipse ry='7' rx='3.5' id='svg_40' cy='177.5' cx='199' strokeWidth='10' stroke='#000' fill='#000000' />
        </svg>
        <div className={'frogSpeech' + (vinylChoice ? ' talking' : '')}>
          {
            collection.length > 0 &&
            <p>{`Out of a toad-tal ${collection.length} vinyls, Discogs Frog chooses:`}</p>
          }
          {
            vinylChoice &&
            <p><strong aria-live='polite'>{vinylChoice}</strong></p>
          }
        </div>
      </div>
      <h3>Enter discogs username(s) to choose a random vinyl from your chosen collection(s)!</h3>
      <form>
        <label htmlFor='users'>Usernames: *</label>
        <span className='extraLabel' id='users-extra-info'>To enter multiple usernames, separate with a comma.</span>
        <textarea required aria-describedby='users-extra-info' id='users' onChange={addUsers} value={users} placeholder='e.g. cheeseman, blueberry-steve, floopySchmooperson' />
        <button type='submit' onClick={findVinyl}>
          {loading ? 'Loading...' : 'Choose Random Vinyl!'}
        </button>
      </form>
    </main>
  )
}

export default DiscogsFrog
