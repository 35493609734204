import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom'

import Dinosaurs from './Dinosaurs'
import Alien from './Alien'

class OtherDoodles extends React.Component {
  render () {
    return (
      <Router>
        <div>
          <nav>
            <ul>
              <li>
                <Link to='/business-dinosaurs'>Business Dinosaurs</Link>
              </li>
              <li>
                <Link to='/aliens'>Something with Aliens</Link>
              </li>
            </ul>
          </nav>

          <Switch>
            <Route path='/business-dinosaurs'>
              <Dinosaurs />
            </Route>
            <Route path='/aliens'>
              <Alien />
            </Route>
          </Switch>
        </div>
      </Router>
    )
  }
}

export default OtherDoodles
