import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom'

import Luna from './Luna'
import Donkey from './Donkey'

class StoryDoodles extends React.Component {
  render () {
    return (
      <Router>
        <div>
          <nav>
            <ul>
              <li>
                <Link to='/luna'>Luna</Link>
              </li>
              <li>
                <Link to='/backwards-speaking-donkey'>The Backwards Speaking Donkey</Link>
              </li>
            </ul>
          </nav>

          <Switch>
            <Route path='/luna'>
              <Luna />
            </Route>
            <Route path='/backwards-speaking-donkey'>
              <Donkey />
            </Route>
          </Switch>
        </div>
      </Router>
    )
  }
}

export default StoryDoodles
