import React from 'react'
import {
  Link
} from 'react-router-dom'

const Home = () => {
  return (
    <main className='App'>
      <h1>Artsite coming soon...</h1>
      <p>In the meantime, please pay a visit to the <Link to='/discogs-frog'>Discogs Frog</Link>!</p>
    </main>
  )
}

export default Home
